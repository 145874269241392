<template>
  <div
    :class="`text-container question ${
      answers.length > 3 ? 'question--small' : ''
    } p-4 m-0`"
  >
    <h3 ref="question">{{ question }} <span class="small-text">{{selected.length}} Selected</span></h3>
    <div v-if="loading" class="overlay"></div>

    <div
      ref="answers"
      v-for="answer in answers"
      @click="selectAnswer(answer.key)"
      :key="answer.key"
      class="question__answer"
      :class="`${selected.includes(answer.key) ? 'selected' : ''}
                  ${
                    questionAnswered
                      ? answer.correct
                        ? 'correct'
                        : 'incorrect'
                      : ''
                  }`"
    >
      <div>
        <span>{{ answer.key }}</span>
      </div>
      <img
        class="indicator"
        v-show="questionAnswered"
        :src="`/assets/images/${
          answer.correct ? 'question-tick.png' : 'question-cross.png'
        }`"
      />
      {{ answer.text }}
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  props: ["question", "answers", "questionAnswered"],
  data() {
    return {
      selected: [],
      loading: false,
    };
  },
  methods: {
    selectAnswer(key) {
      if(this.selected.length == 3) {
        return
      } else {
        this.selected.push(key)
      }
    }
  },
  mounted() {
    gsap
      .timeline()
      .to(this.$refs.question, { opacity: 1, delay: 0.5, duration: 0.5 })
      .to(this.$refs.answers, {
        opacity: 1,
        delay: 0.2,
        duration: 1,
        stagger: 0.2,
      });
  },
  watch: {
    selected(value) {
      if(value.length == 3) {
        this.loading = true;
        setTimeout(() => {
          this.questionAnswered = true;
          this.loading = false;
          this.$emit("validateAnswer", true);
        }, 1000);

      }
    }
  }
};
</script>

<style lang="scss" scoped>
  h3 {
    margin-bottom: 20px;
  }
  .small-text {
    font-size: 16px;
    float: right;
    background: $grey;
    color: $white;
    padding: 5px 10px;
  }
</style>