<template>
  <div class="intro">
    <div class="container">
      <div class="row align-items-center pt-3">
        <div class="col-12 col-lg-6 pt-3 pt-lg-0 text-center text-lg-start">
          <h1 class="heading" v-html="pageTitle" />
        </div>
      </div>
      <div class="row justify-content-center align-items-center mid-row">
        <div class="col-12 col-md-10 col-lg-10 col-xl-8">
          <vimeoPlayer v-model="videoEnded" :videoId="videoUrl" />
        </div>
      </div>
      <div class="row pb-2 pb-md-0 justify-content-between align-items-end bottom-row">
        <div class="col-6 col-md-1">
          <img class="img-fluid logo" src="/assets/images/logo.png" />
        </div>
        <div class="col-6 col-md-1 pe-4 pe-md-0 d-flex d-md-block justify-content-end">
          <div v-if="videoEnded" ref="nextButton"  class="next-btn-wrapper mb-3">
            <router-link :to="link" class="next-btn">Next</router-link>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="col-12 footer-links bg--white">
          © AstraZeneca
          <span>|</span>
          <a href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html" target="_blank">Legal notice and Terms of Use</a>
          <span>|</span>
          <a href="https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html" target="_blank">Privacy Notice</a>
          <span>|</span>
          <a href="//policy.cookiereports.com/fe01e9b6-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
          <span>|</span>
          <a href="https://www.astrazeneca.com/our-company/Contact-us.html" target="_blank">Contact us</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { gsap } from "gsap";
import video from "../components/Video.vue";
import VoiceHelper from "../components/VoiceHelper.vue";

export default {
  name: "page-1",
  components: {
    vimeoPlayer: video,
    VoiceHelper
  },
  data() {
    return {
        videoEnded: false
    }
  },
  computed: {
    pageTitle() {
      return this.$store.getters.content.intro.title;
    },
    videoUrl() {
      return this.$store.getters.content.intro.videoUrl;
    },
    link() {
      return this.$store.getters.content.intro.link;
    },
  },
  watch: {
    videoEnded(value) {
      if(value) {
        setTimeout(() => {
          gsap.to(this.$refs.nextButton, { opacity: 1,  y: -20 })
        }, 100);
      }
    }
  }
};
</script>
